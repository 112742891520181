exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-project-astrolink-app-js": () => import("./../../../src/pages/project/astrolink-app.js" /* webpackChunkName: "component---src-pages-project-astrolink-app-js" */),
  "component---src-pages-project-astrolink-pwa-js": () => import("./../../../src/pages/project/astrolink-pwa.js" /* webpackChunkName: "component---src-pages-project-astrolink-pwa-js" */),
  "component---src-pages-project-front-hub-js": () => import("./../../../src/pages/project/front-hub.js" /* webpackChunkName: "component---src-pages-project-front-hub-js" */),
  "component---src-pages-project-livecam-js": () => import("./../../../src/pages/project/livecam.js" /* webpackChunkName: "component---src-pages-project-livecam-js" */),
  "component---src-pages-project-messenger-js": () => import("./../../../src/pages/project/messenger.js" /* webpackChunkName: "component---src-pages-project-messenger-js" */),
  "component---src-pages-project-tangram-js": () => import("./../../../src/pages/project/tangram.js" /* webpackChunkName: "component---src-pages-project-tangram-js" */),
  "component---src-pages-project-video-communication-js": () => import("./../../../src/pages/project/video-communication.js" /* webpackChunkName: "component---src-pages-project-video-communication-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

